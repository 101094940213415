// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as SignUpErrorScss from "./SignUpError.scss";

var css = SignUpErrorScss;

function SignUpLastNameError(props) {
  var results = props.results;
  if (results === undefined) {
    return null;
  }
  var message = results.lastName;
  if (message.TAG === "Ok") {
    return null;
  } else {
    return JsxRuntime.jsx("div", {
                children: message._0,
                className: css.fieldError
              });
  }
}

var make = SignUpLastNameError;

export {
  css ,
  make ,
}
/* css Not a pure module */
